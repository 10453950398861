import React from "react";
const Footer = () => {
  return (
    <footer className="flex md:flex-row flex-col items-top bg-[#333333] md:px-[261px]">
      <div className="flex-grow-[1] flex-shrink-0 py-[30px] md:py-[52px] px-[24px] md:px-0">
        <div className="flex flex-row items-center cursor-pointer">
          <img src="/img/ic_logo.png" className="w-[37px]" alt="logo" />
          <p className="text-[20px] text-[white] font-medium ml-[9px]">규장각 디지털 헤리티지</p>
        </div>
        <div className="block md:hidden py-[30px] md:py-[27px] md:px-0">
          <div className="flex flex-row items-left justify-start">
            <a target="_blank" href="https://nft-labs.s3.ap-northeast-1.amazonaws.com/docs/%EC%9D%B4%EC%9A%A9%EC%9E%90+%EA%B0%80%EC%9D%B4%EB%93%9C_%EC%9B%90%ED%99%94+%EA%B3%84%EC%A2%8C%EC%9D%B4%EC%B2%B4.pdf" download="이용자 가이드 (원화 계좌이체).pdf">
              <span className="text-[12px] text-white ">이용자 가이드 (원화 계좌이체)</span>
              <img src="/img/folder.png" className="w-[16px] ml-[3px]" alt="folder" />
            </a>
          </div>
          <div className="flex flex-row items-left justify-start">
            <a target="_blank" href="https://nft-labs.s3.ap-northeast-1.amazonaws.com/docs/%EC%9D%B4%EC%9A%A9%EC%9E%90+%EA%B0%80%EC%9D%B4%EB%93%9C_%ED%81%B4%EB%A0%88%EC%9D%B4+%EA%B5%AC%EB%A7%A4.pdf" download="이용자 가이드 (클레이 구매).pdf">
              <span className="text-[12px] text-white ">이용자 가이드 (클레이 구매)</span>

              <img src="/img/folder.png" className="w-[16px] ml-[3px]" alt="folder" />
            </a>
          </div>
        </div>
        <p className="text-[12px] text-white mt-[40px] md:mt-[60px] hidden md:block">
          ㈜엔에프티랩 | 서울특별시 강남구 역삼로 156, 태광빌딩 608호 | 대표 장지훈
          <br />
          사업자등록번호 833-86-02703 | 문의 contact@nftlab.biz
          <br />
          <br />
          COPYRIGHT © KYUJANGGAK DIGITAL HERITAGE. ALL RIGHTS RESERVED.
        </p>
        <p className="text-[12px] text-white md:mt-[60px] block md:hidden">
          ㈜엔에프티랩 | 서울특별시 강남구 역삼로 156,
          <br />
          태광빌딩 608호 | 대표 장지훈
          <br />
          사업자등록번호 833-86-02703 | 문의 contact@nftlab.biz
          <br />
          <br />
          COPYRIGHT © KYUJANGGAK DIGITAL HERITAGE.
          <br />
          ALL RIGHTS RESERVED.
        </p>
      </div>
      <div className="hidden md:block flex-grow-0 flex-shrink-0 py-[30px] md:py-[56px] px-[24px] md:px-0  text-right">
        <div className="flex flex-row items-right justify-end">
          <a target="_blank" href="https://nft-labs.s3.ap-northeast-1.amazonaws.com/docs/%EC%9D%B4%EC%9A%A9%EC%9E%90+%EA%B0%80%EC%9D%B4%EB%93%9C_%EC%9B%90%ED%99%94+%EA%B3%84%EC%A2%8C%EC%9D%B4%EC%B2%B4.pdf" download="이용자 가이드 (원화 계좌이체).pdf">
            <span className="text-[12px] text-white ">이용자 가이드 (원화 계좌이체)</span>
            <img src="/img/folder.png" className="w-[16px] ml-[3px]" alt="folder" />
          </a>
        </div>
        <div className="flex flex-row items-right justify-end">
          <a target="_blank" href="https://nft-labs.s3.ap-northeast-1.amazonaws.com/docs/%EC%9D%B4%EC%9A%A9%EC%9E%90+%EA%B0%80%EC%9D%B4%EB%93%9C_%ED%81%B4%EB%A0%88%EC%9D%B4+%EA%B5%AC%EB%A7%A4.pdf" download="이용자 가이드 (클레이 구매).pdf">
            <span className="text-[12px] text-white ">이용자 가이드 (클레이 구매)</span>

            <img src="/img/folder.png" className="w-[16px] ml-[3px]" alt="folder" />
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
